<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <meta content="width=device-width, initial-scale=1.0" name="viewport" />

    <title>Butler Support</title>
    <meta content="" name="description" />
    <meta content="" name="keywords" />

    <!-- Favicons -->
    <link
      href="../../../../assets/under_maintenance/img/apple-touch-icon.png"
      rel="apple-touch-icon"
    />

    <!-- Google Fonts -->
    <link
      href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Jost:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
      rel="stylesheet"
    />
  </head>

  <body>
    <div style="display: flex; justify-content: center"></div>
    <!-- ======= Hero Section ======= -->
    <section id="hero" class="d-flex align-items-center">
      <div class="container">
        <div class="row">
          <div
            class="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div class="title">
              <h1>Support</h1>
              <img
                class="title-img"
                style="width: 25%; margin-left: 20px"
                src="../../../../assets/under_maintenance/img/art1.png"
              />
            </div>
            <p style="font-size: 20px; font-weight: 300; color: white">
              If you have any questions or need help, please feel free to
              contact us at
              <span class="" style="color: black"
                ><span style="font-weight: bold">help@butlerapp.info</span>
                <br />
                <p id="contact-info">
                  Or you could also contact us by opening a support ticket in
                  our application
                </p>
              </span>
            </p>

            <div
              class="store-icons d-flex justify-content-center justify-content-lg-start"
            >
              <img
                style="height: 30%"
                src="../../../../assets/under_maintenance/img/playstore.png"
              />
              <img
                style="height: 30%; margin-top: 3px; margin-left: 20px"
                src="../../../../assets/under_maintenance/img/appstore.png"
              />
            </div>
          </div>
          <div
            class="col-lg-6 order-1 order-lg-2 hero-img"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <img
              src="../../../../assets/under_maintenance/img/hero-img.png"
              class="animated"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
    <!-- End Hero -->

    <!-- ======= Footer ======= -->

    <div id="preloader"></div>
    <a
      href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>
  </body>
</html>
